
import { Options, Vue } from 'vue-class-component'
import Patient from '@/models/Patient'
import RadioN from '@/components/RadioN.vue'
import { mapActions, mapGetters } from 'vuex'
import ability from '@/services/ability'

@Options({
  components: {
    RadioN
  },
  methods: mapActions([
    'updateCombinedPreferences',
    'downloadCombinedPreferences'
  ]),
  computed: mapGetters(['patient', 'combinedPreferences'])
})
export default class CombinedPreferences extends Vue {
  patient!: Patient
  combinedPreferences!: Patient['combinedPreferences']

  // Actions
  updateCombinedPreferences!: (payload: {
    id: number
    combinedPreferences: Patient['combinedPreferences']
  }) => Promise<void>

  downloadCombinedPreferences!: () => Promise<void>

  async created (): Promise<void> {
    await this.downloadCombinedPreferences()
  }

  async submit (): Promise<void> {
    try {
      await this.updateCombinedPreferences({
        id: this.patient.id,
        combinedPreferences: this.combinedPreferences
      })

      if (ability.can('view', 'recommendations')) {
        this.$router.push({
          name: this.$route.meta.next,
          params: { id: this.patient.id }
        })
      } else {
        this.$router.push({
          name: this.$route.meta.fallback,
          params: { id: this.patient.id }
        })
      }
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
