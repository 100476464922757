
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: Number,
    text: String,
    n: {
      type: Number,
      default: 9
    },
    disabledOptions: {
      type: Array,
      default: []
    },
    subQuestionLvl: Number
  }
})
export default class RadioN extends Vue {
  text!: string
  value!: number
  mutableValue!: number
  options!: boolean[]
  subQuestionLvl!: number
  n!: number
  disabledOptions!: Array<number>

  /**
   * Work arrownd for rerendering the buttons with :key atribute.
   * All the info: https://michaelnthiessen.com/force-re-render/
   */
  rerender = 0

  created (): void {
    this.options = new Array(this.n).fill(false)
    this.mutableValue = this.value - 1
    this.options[this.mutableValue] = true
  }

  emit (index: number): void {
    this.options[this.mutableValue] = false
    this.mutableValue = index
    this.options[this.mutableValue] = true
    this.rerender += 1
    this.$emit('changed', index + 1)
    this.$emit('change')
  }
}
